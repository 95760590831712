/* eslint-disable */
const sendEvent = eventCallback => {
   if (!window.gtag) {
      return;
   }

   // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
   if (`requestAnimationFrame` in window) {
      requestAnimationFrame(() => {
         requestAnimationFrame(eventCallback);
      });
   } else {
      // simulate 2 rAF calls
      setTimeout(eventCallback, 32);
   }
};

export const pageView = () => {
   const sendPageView = () => {
      const pagePath = location ? location.pathname + location.search + location.hash : undefined;
      window.gtag(`event`, `page_view`, { page_path: pagePath });
   };

   sendEvent(sendPageView);
};

export const event = (category, label, rest) => {
   const event = () => {
      window.gtag('event', 'click', {
         event_category: category,
         event_label: label,
         ...rest,
      });
   };

   sendEvent(event);
};

export const outboundClick = label => event('outbound', label, { transport_type: 'beacon' });
