import scroll from './src/helpers/scroll';
window.origScroll = window.scrollTo;
window.scrollTo = scroll;
import * as ga from './src/utils/ga';

export const onClientEntry = () => {
   if (window === undefined || !window.gtag) {
      return;
   }

   if (
      document.cookie.split(';').some(item => item.includes('gatsby-plugin-google-analytics-gdpr_cookies-enabled=true'))
   ) {
      window.gtag('consent', 'update', {
         analytics_storage: 'granted',
      });
   }
};

export const onInitialClientRender = () => {
   ga.pageView();
};

export const onRouteUpdate = () => {
   scroll(`/${window.location.hash}`);
};
