import scrollTo from 'gatsby-plugin-smoothscroll';

export default hash => {
   if (!hash || hash === '/') {
      if (hash !== 0) {
         window.origScroll(0, 0);
      }
      return;
   }

   const node = document.querySelector(`#to-${hash.replace('/#', '')}`);
   if (node) {
      scrollTo(`#to-${hash.replace('/#', '')}`);
   }
};
